<template>
	<div class="look-report">
		<header class="base-header am-cf">
			<div class="header-logo am-fl">
				<img src="/assets/img/logo.png" alt="logo">
			</div>
		</header>
		
		<div class="tips-anquan-cell">
			<div class="tips-anquan">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
		</div>
		<!-- 左侧栏信息展示 -->
		<aside class="left-side bg-fff fixed am-scrollable-vertical">
			<div class="grade">
				<span class="grade_score_title">您的健康评分</span>
				<div>
					<!-- <b class="score_color2 ac-default">85</b>
					<span class="score_color2">分(良好)</span>
					<img class="xiaolian_img" src="../assets/img/health_1.png" > -->
				</div>
			</div>
			<div class="side-person am-text-center">
				<ul class="am-avg-sm-3 am-avg-md-3 am-avg-lg-3">
					<li>
						
						<div class="side-person-life am-vertical-align">
							<div class="am-vertical-align-middle am-text-xs">
								<p class="health-type am-text-sm side-person-age fontw-bold ac-default">--</p>
								<p>健康风险系数</p>
							</div>
						</div>
						<div class="side-person-life am-vertical-align">
							<div class="am-vertical-align-middle am-text-xs">
								<p class="side-person-age am-text-xs fontw-bold ac-default am-text-nowrap">
									<span class="lift-fee am-text-lg ac-default">--</span>元
								</p>
								<p>--</p>
							</div>
						</div>
					</li>
					<li>
						<img class="renwu" src="" >
					</li>
					<li>
						<div class="side-person-life am-vertical-align">
							<div class="am-vertical-align-middle am-text-xs">
								<p class="side-person-age fontw-bold ac-default">
									<span class="lift-rate fonts-20">0</span>%
								</p>
								<p>百岁概率</p>
							</div>
						</div>
						<div class="side-person-life am-vertical-align pre-lift-item">
							<div class="am-vertical-align-middle am-text-xs">
								<p class="side-person-age fontw-bold ac-default">
									<span class="pre-lift fonts-20">0</span>%
								</p>
								<p>九十岁概率</p>
							</div>
						</div>
						
					</li>
				</ul>
			</div>
			<div class="side-basic">
				<h6>基本信息</h6>
				<ul class="am-avg-sm-3 am-avg-md-3 am-avg-lg-3">
					<li>
						<dl class="am-text-sm">
							<dt>身高</dt>
							<dd>
								<span class="height am-text-default fontw-bold color-extr">--</span>cm
							</dd>
						</dl>
					</li>
					<li>
						<dl class="am-text-sm">
							<dt>体重</dt>
							<dd>
								<span class="weight am-text-default fontw-bold color-extr">--</span>kg
							</dd>
						</dl>
					</li>
					<li>
						<dl class="am-text-sm">
							<dt>体脂率</dt>
							<dd>
								<span class="bfr am-text-default fontw-bold color-extr">--</span>%
							</dd>
						</dl>
					</li>
					<li>
						<dl class="am-text-sm waist_box">
							<dt>腰围<span class="am-hide waist-tip">推算值</span></dt>
							<dd>
								<span class="waistline am-text-default fontw-bold color-extr">--</span>cm
							</dd>
						</dl>
					</li>
					<li>
						<dl class="am-text-sm hip_box">
							<dt>臀围<span class="am-hide hip-tip">推算值</span></dt>
							<dd>
								<span class="hipline am-text-default fontw-bold color-extr">--</span>cm
							</dd>
						</dl>
					</li>
					<li class="chest-li">
						<dl class="am-text-sm">
							<dt>胸围</dt>
							<dd>
								<span class="chest am-text-default fontw-bold color-extr">--</span>cm
							</dd>
						</dl>
					</li>
				</ul>
			</div>
			<div class="side-biochemistry">
				<h6>生化信息</h6>
				<ol class="am-hide">
					<li class="am-u-sm-4 am-u-md-4 am-u-lg-4">
						<dl class="am-text-sm">
							<dt>收缩压</dt>
							<dd>
								<p class="sbp am-text-default fontw-bold color-extr">--</p>
								<span class="am-text-xs">
									mmHg
								</span>
							</dd>
						</dl>
					</li>
					<li class="am-u-sm-4 am-u-md-4 am-u-lg-4">
						<dl class="am-text-sm">
							<dt>舒张压</dt>
							<dd>
								<p class="dbp am-text-default fontw-bold color-extr">--</p>
								<span class="am-text-xs">
									mmHg
								</span>
							</dd>
						</dl>
					</li>
					<li class="am-u-sm-4 am-u-md-4 am-u-lg-4">
					</li>
				</ol>
				<ul class="am-g">
				
				</ul>
			</div>
			<!-- <div class="side-modify-information">
				<button @click="toUpdate" type="button" class="am-btn am-text-sm am-btn-block bg-btn-default fontw-bold">修改信息</button>
				<p class="am-text-xs am-text-nowrap">注：您可以修改您的体型信息和生化指标信息（仅限一次）</p>
			</div> -->
		</aside>
		
		<!-- 骨折信息左侧栏 -->
		<aside class="left-side bg-fff fixed am-scrollable-vertical" id="fb-left" >
			<div class="risk-wrap">
				<div class="risk">
					<div class="risk-title">如果发生骨折,身体各部位发生骨折的风险概率</div>
					<div class="human">
						<div class="human-box">
							<img src="/assets/img/gz-man.png" class="human-img" alt="">
							<div class="human-head">
								<div class="icon-blue" id="head-tb"></div>
								<div class="line"></div>
								<div class="value">头部<span> </span></div>
							</div>
							<div class="human-vertebra">
								<div class="value" >颈椎部<span> </span></div>
								<div class="line"></div>
								<div class="icon-blue" id="vertebra-jz"></div>
							</div>
							<div class="human-limb">
								<div class="icon-blue" id="limb-sz"></div>
								<div class="line"></div>
								<div class="value">上肢<span> </span></div>
							</div>
							<div class="human-legs">
								<div class="value">下肢<span> </span></div>
								<div class="line"></div>
								<div class="icon-blue" id="legs-xz"></div>
							</div>
						</div>
						<div class="flex list">
							<div class="item head-box">
								<img src="/assets/img/gz-tb.png" class="item-img" alt="">
								<div class="item-info head-item	">
									<p class="title">头部</p>
									<p class="value"><span class="value-span"></span>%</p>
								</div>
							</div>
							<div class="item vertebra-box">
								<img src="/assets/img/gz-jz.png" class="item-img" alt="">
								<div class="item-info vertebra-item">
									<p class="title">颈椎部</p>
									<p class="value"><span class="value-span"></span>%</p>
								</div>
							</div>
							<div class="item limb-box">
								<img src="/assets/img/gz-sz.png" class="item-img" alt="">
								<div class="item-info limb-item">
									<p class="title ">上肢</p>
									<p class="value"><span class="value-span"></span>%</p>
								</div>
							</div>
							<div class="item legs-box">
								<img src="/assets/img/gz-xz.png" class="item-img" alt="">
								<div class="item-info legs-item">
									<p class="title">下肢</p>
									<p class="value"><span class="value-span"></span>%</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="side-modify-information">
				<button @click="toUpdate" type="button" class="am-btn am-text-sm am-btn-block bg-btn-default fontw-bold">修改信息</button>
				<p class="am-text-xs">注：您可以修改您的体型信息、问卷信息、生化指标信息(仅限一次)</p>
			</div> -->
		</aside>
		
		
		<!-- 右侧固定内容 -->
		<aside class="right-side fixed">
			<div id="healthNow" class="active">
				<span class="active am-text-sm color-extr">
					健康现状评估
					<i class="am-icon-caret-right am-text-xs"></i>
				</span>
				<ul class="am-text-center">
					<li class="active" data-label='result-score'>
						<div class="am-text-xs">健康评分</div>
					</li>
					<li data-label='result-total'>
						<div class="am-text-xs">总体健康评估和生命预测</div>
					</li>
					<li data-label='health-realage'>
						<div class="am-text-xs">健康风向标——生理年龄</div>
					</li>
					<li data-label='result-bodily'>
						<div class="am-text-xs">体型评估</div>
					</li>
					<li data-label='result-biochemical'>
						<div class="am-text-xs">生化指标评估</div>
					</li>
					<li data-label='latent-dis'>
						<div class="am-text-xs">潜在核心疾病群风险评估</div>
					</li>
					<!-- <li data-label='three-highs'>
						<div class="am-text-xs">健康风向标——四高风险倍率评估</div>
					</li> -->
				</ul>
			</div>
			<div id="healthFuture">
				<span class="am-text-sm color-extr">
					未来健康预测
					<i class="am-icon-caret-right am-text-xs"></i>
				</span>
				<ul class="absolute am-text-center">
					<li data-label='result-illness'>
						<div class="am-text-xs">未来疾病风险预测</div>
					</li>
					<li data-label='illness-cancer'>
						<div class="am-text-xs">健康风向标——癌症风险预测</div>
					</li>
					<li data-label='result-medical'>
						<div class="am-text-xs am-vertical-align-middle">医疗费用预测</div>
					</li>
				</ul>
			</div>
			<div id="healthSelf">
				<span class="am-text-sm color-extr">
					个人健康管理
					<i class="am-icon-caret-right am-text-xs"></i>
				</span>
				<ul class="am-text-center">
					<li data-label='result-noSick'>
						<div class="am-text-xs">不患病&amp;增寿</div>
					</li>
					<li data-label='improve-biochemical'>
						<div class="am-text-xs">生化值改善目标</div>
					</li>
					<li data-label='health-improve'>
						<div class="am-text-xs">健康管理和改善方案</div>
					</li>
				</ul>
			</div>
			<div class="fb-style" id="fb">
				<span class="am-text-sm color-extr">
					骨密度风险评估
					<i class="am-icon-caret-right am-text-xs"></i>
				</span>
				<ul class="absolute am-text-center">
					<li data-label='fb-status'>
						<div class="am-text-xs">骨质状况分析</div>
					</li>
					<li data-label='fb-risk'>
						<div class="am-text-xs">未来5年/10年骨折风险概率</div>
					</li>
					<li data-label='fb-probability'>
						<div class="am-text-xs">因骨折导致严重伤害的概率</div>
					</li>
					<li data-label='fb-tscore'>
						<div class="am-text-xs">骨密度值改善建议</div>
					</li>
					<li data-label='fb-diet'>
						<div class="am-text-xs">生活饮食改善建议</div>
					</li>
				</ul>
			</div>
		</aside>
		
		<div class="result-list">
			
			<h2>健康现状评估</h2>
			<div class="result-score">
				<h3 class="am-text-lg">健康评分</h3>
				<div class="module_explain_score">
					<p>米因以大数据为基础，综合个人的当前健康状况、潜在疾病风险和未来健康风险进行整体评分，给出百分制的「健康分」，以求尽可能对整体健康进行量化评估，既可用于表示个人健康状况，也可作为个人健康管理的目标和评价标准。</p>
					<p class="font_weight">对于个人而言，分数越高则相对越健康，健康分不适用于85岁以上的长者。</p>
				</div>
				<div class="score_wrap">
					<div class="score_box">
						<div class="score_chat">
							<!-- <p>您的健康评分</p>
							<b>85</b>
							<span>分(良好)</span> -->
						</div>
						<div class="score_line_bg">
							<div class="score_one_bg"></div>
							<div class="score_d1"></div>
							<div class="score_two_bg"></div>
							<div class="score_d2"></div>
							<div class="score_three_bg"></div>
							<div class="score_line_top"></div>
						</div>
						<div class="score_line_text">健康分等级：100-90分以上视为「优等」；89-60分视作「良好」；59-1分视为「欠佳」。</div>
					</div>
					<div class="score_item_title">健康是人生的动态历程</div>
					<div class="score_health_wrap">
						<div class="score_health_list">
							<div class="score_health_item">健康</div>
							<div class="score_health_item_icon"></div>
							<div class="score_health_item">亚健康</div>
							<div class="score_health_item_icon"></div>
							<div class="score_health_item">患病者 的过程是可逆的</div>
						</div>
						<div class="score_health_text">因不良生活习惯导致患病的慢病患者，在适当的医疗介入与生活习惯改善后，完全有可能逐渐康复、不再复发。</div>
					</div>
					<div class="score_item_title">影响个人健康的因素</div>
					<div class="score_health_wrap">
						<div class="score_health_list">
							<div class="score_tag">基因遗传</div>
							<div class="score_tag">生活环境</div>
							<div class="score_tag">生活习惯</div>
							<div class="score_tag">医疗支持</div>
							<div class="score_tag">特殊因素</div>
						</div>
					</div>
					<div class="score_item_title">不同人群影响健康分的因素</div>
					<div class="score_health_wrap">
						<div class="score_type_text"><span>医疗需求人群</span> 同一或不同疾病间都有轻重程度之别，在医疗科技与健康意识进步下，疾病的可控度与可愈度已进步很多，疾病未必会造成身心失衡或影响活力，因此患病者也有可能在平稳控制病情和保持良好生活习惯的情况下，获得比较高的健康分。</div>
						<div class="score_type_text"><span>亚健康人群</span> 往往在不良生活习惯的影响下，身体已有许多潜在疾病但尚未表露，表面看似仅是忙碌劳累，实则是在透支生命与健康。这种情况下往往只需要一两次急症发作，便会全面引爆潜藏的健康危机，导致个人健康状况的迅速恶化。如果您认为自己较为健康，但却获得了不理想的健康分结果，还请您提高警惕，更多关爱自身。</div>
					</div>
					<div class="score_btn_cell">
						在人群大数据的维度中，健康分与各类疾病、重大伤病、门诊次数、医疗费用、住院天数甚至死亡率等均有高度相关性。<span class="score_popup_btn">查看详情</span>
					</div>
					<div class="score_btn_tip">请注意，人群大数据的健康分关联度统计并不代表您的个人健康风险。<br>
健康分是一项综合评分，每个人的具体健康风险都是不一样的，请参照本报告的相关结果了解您自身的健康状况和健康风险。</div>
				</div>
			</div>
			<!-- 总体健康评估和生命预测 -->
			<div class="result-total">
				<h3 class="am-text-lg">总体健康评估和生命预测</h3>
				<div class="module-explain">
					<p>现时科技与生活环境之下，人类的寿限约120岁；但人类平均寿命约在80岁左右，距离120岁的寿限还有很大的进步空间。现在医疗科技进步，人类普遍面临的是“生命”延长了，但因为不健康而生病，将导致不便或卧床，影响生活与生命品质，造成痛苦苟延残喘的时间也可能跟著延长。我们将可能影响生活品质的时间当成“失能时间”。</p>
					<p>不生病或延缓病情，降低疾病对健康的影响，必须透过健康促进与健康管理，才能“防未病、断欲病、缓既病”，进而有效的“延长健康的余命”</p>
				</div>
				<ul class="am-g">
					<li class="result-total-left am-u-sm-4 am-u-md-4 am-u-lg-4 am-cf am-text-xs">
						<div class="bg-fff am-cf wh-100">
							<dl class="bg-fff am-fl">
								<dd class="ac-default">
									<span class="pre-lift am-text-xl fontw-bold">--</span>%
								</dd>
								<dt class="am-text-xs fontw-normal">九十岁概率</dt>
							</dl>
							<img class="lifetime-img am-fr" src="/assets/img/yuqishouming.png" alt="">
						</div>
					</li>
					<li class="result-total-center am-u-sm-4 am-u-md-4 am-u-lg-4 am-cf am-text-xs">
						<div class="bg-fff am-cf wh-100">
							<dl class="bg-fff am-fl">
								<dd class="ac-default">
									<span class="lift-rate am-text-xl fontw-bold">--</span>%
								</dd>
								<dt class="am-text-xs fontw-normal">百岁概率</dt>
							</dl>
							<img class="centenary-img am-fr" src="/assets/img/baisuigailv.png" alt="">
						</div>
					</li>
					<li class="result-total-right am-u-sm-4 am-u-md-4 am-u-lg-4 am-cf am-text-xs">
						<div class="bg-fff am-cf wh-100">
							<dl class="bg-fff am-fl">
								<dd class="ac-default">
									<span class="disability am-text-xl fontw-bold">--</span>%
								</dd>
								<dt class="am-text-xs fontw-normal">
									失能的平均概率
									<i class="iconfont iconyiwen1 relative">
										<div class="help-tooltip am-text-left bg-fff shadow-default absolute am-text-xs">
											<div class="triangle shadow-default absolute"></div>
											指所有影响生活和自理能力的情况，如腰疼、无力、重听、视力减退、骨折、因病卧床等。
										</div>
									</i>
								</dt>
							</dl>
							<img class="disability-img am-fr" src="/assets/img/shinengpingjungailv.png" alt="">
						</div>
					</li>
				</ul>
				<div class="result-total-chart">
					
				</div>
			</div>
			
			<!-- 未来健康风险预测 -->
			<div class="result-health am-hide">
				<!-- 健康：综合生理年龄Real Age -->
				<div class="result-health-realage health-realage am-hide margin-t-20">
					<h3 class="am-text-lg">生理年龄</h3>
					<div class="module-explain">
						<p>测量综合生理年龄RealAge，目的是在希望您能体会个人健康现况，可以更注重个人健康促进，让您未来能表现的更为年轻有活力。错误或不好的生活习惯，会让人身体生理机能的年龄比实际岁数老化，并增加罹病机率和死亡机率。</p>
						<p>综合生理年龄RealAge，是经研究分析海量的健康数据库，依照个人的健康危险因子（如：生活习惯、运动饮食、….等）的多寡与影响程度，分别给予正负分来计算，换句话说：RealAge＝实际年龄±影响健康危险因子之加总值。</p>
						<p>透过综合生理年龄RealAge的检测，了解健康危险因子对自己身体生理的影响程度，未来能加强良好饮食、运动、生活习惯，确实做好健康管理、预防保健与养生，以延缓老化、降低罹病与失能机率。</p>
					</div>
					<div class="result-health-tit am-text-xs am-cf shadow-default bg-fff am-text-center">
						<dl class="am-fl wid-50">
							<dd class="ac-default fontw-bold">
								<span class="age am-text-lg">--</span>岁
							</dd>
							<dt class="fontw-normal">实际年龄</dt>
						</dl>
						<dl class="am-fl wid-50">
							<dd class="ac-default fontw-bold">
								<span class="real-age am-text-lg">--</span>岁
							</dd>
							<dt class="fontw-normal">
								综合生理年龄Real Age
							</dt>
						</dl>
					</div>
					<p class="result-health-ms bg-fff am-text-sm">综合生理年龄是从医学、生物学的角度来衡量的年龄，表示身体的成长、成熟或衰老的程度。米因在千万人口的医疗健康大数据库基础上，挖掘出综合生理年龄所对应的关键指标，为您提供详细、准确的大数据分析结果。</p>
					
				</div>
			</div>
			
			<!-- 体型评估 -->
			<div class="result-bodily">
				<h3 class="am-text-lg">体型评估</h3>
				<div class="module-explain">
					<p>体型（如身体质量指数BMI、腰臀比WHR、体脂率FAT等）数据常是健康与否的最重要指标之一，体型数据失衡时，往往是内在核心生化指标发生重要改变的外在表现，常是慢性病发生的先兆。临床研究指出如肥胖或过重易引起四高疾患（高血压、高血脂、糖尿病、痛风）等长期慢性疾病，因此日常依据体型（即身高、体重、腰围、臀围等）评估的结果，进行个人体型的管理，对健康管理是最简单易行且助益颇大。</p>
					<p>米因依据逾2500万华人的健康大数据，评估您的体型，与临床标准相互比较下，找出您的体型需维持或改善的项目（含BMI、腰臀比和体脂率）与目标，并以此为根据找出健康管理（如饮食热量、运动等）的目标与方法。</p>
					<p>透过体型评估，可以知道身体的警讯在哪，进而了解未来可能罹患慢性疾病的风险，加强个人健康意识，会更有动力依据团队健康管理目标，进行相关的健康促进作为。</p>
				</div>
				<div class="result-bodily-BMI shadow-default bg-fff">
					<h3 class="am-text-lg">
						1.BMI
						<!-- <span class="hint"></span> -->
					</h3>
					<div class="you">
						<p class="sub-title c-default am-text-sm">
			                您的BMI：<span class="BMI">--</span>
			            </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-low wid-33 am-fl">
									<span>18.5</span>
								</li>
								<li class="step2 bg-default wid-33 am-fl">
									<span>23</span>
								</li>
								<li class="step3 bg-high wid-33 am-fl">
									<!-- <span>27</span> -->
								</li>
								<!-- <li class="step4 bg-danger wid-25 am-fl">
								</li> -->
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-33"><i></i></li>
								<li class="step2 am-fl wid-33"><i></i></li>
								<li class="step3 am-fl wid-33"></li>
							</ol>
						</div>
					</div>
					<div class="mean">
						<p class="sub-title c-default am-text-sm am-cf">
					         同族群平均值的BMI：
							 <span class="BMI-av">--</span>
							 <b class="am-fr am-text-xs">
								 同族群（PLM，People Like Me）:与您相似的人群--与您同性别同年龄段的人群
							 </b>
					    </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-low wid-33 am-fl">
									<span>18.5</span>
								</li>
								<li class="step2 bg-default wid-33 am-fl">
									<span>23</span>
								</li>
								<li class="step3 bg-high wid-33 am-fl">
									<!-- <span>27</span> -->
								</li>
								<!-- <li class="step4 bg-danger wid-25 am-fl">
								</li> -->
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-33"><i></i></li>
								<li class="step2 am-fl wid-33"><i></i></li>
								<li class="step3 am-fl wid-33"></li>
							</ol>
						</div>
					</div>
					<div class="introduce am-text-xs">
						<p>BMI指数是用体重公斤数除以身高米数平方得出的数字，是目前国际上常用的衡量人体胖瘦程度以及是否健康的一个标准。</p>
						<p>临床标准范围：&lt;18.5表示过轻，18.5～23表示正常，&gt;23表示肥胖。</p>
					</div>
				</div>
				<div class="result-bodily-WHR bg-fff shadow-default">
					<h3 class="am-text-lg">
						2.腰臀比(WHR)
						<!-- <span class="hint"></span> -->
					</h3>
					<div class="you">
						<p class="sub-title c-default am-text-sm">
			                您的WHR：
							<span class="WHR ac-default">--</span>
				        </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-default wid-50 am-fl">
									<span>0.92</span>
								</li>
								<li class="step2 bg-danger wid-50 am-fl">
								</li>
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-50"><i></i></li>
							</ol>
						</div>
					</div>
					<div class="mean">
						<p class="sub-title c-default am-text-sm">
							 同族群平均值的WHR：
							 <span class="WHR-av">--</span>
							 <b class="am-text-xs am-fr">
								 同族群（PLM，People Like Me）:与您相似的人群--与您同性别同年龄段的人群
							 </b>
					    </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-default wid-50 am-fl">
									<span>0.92</span>
								</li>
								<li class="step2 bg-danger wid-50 am-fl">
								</li>
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-50"><i></i></li>
							</ol>
						</div>
					</div>
					<div class="introduce am-text-xs">
						<p>腰围反映脂肪总量和脂肪分布的综合指标,臀围反映髋部骨骼和肌肉的发育情况。腰臀比值越大，腰腹或内脏就有可能堆积更多的脂肪。</p>
						<p>临床标准范围：男性&lt;0.92，女性&lt;0.88。</p>
					</div>
				</div>
				<div class="result-bodily-BFR bg-fff shadow-default">
					<h3 class="am-text-lg">
						3.体脂率(BFR)
						<!-- <span class="hint"></span> -->
					</h3>
					<div class="you">
						<p class="sub-title c-default am-text-sm">
				            您的BFR：
							<span class="BFR ac-default">--</span>
				        </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-low wid-33 am-fl">
									<span>17.0</span>
								</li>
								<li class="step2 bg-default wid-33 am-fl">
									<span>23.0</span>
								</li>
								<li class="step3 bg-danger wid-33 am-fl">
								</li>
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-33"><i></i></li>
								<li class="step1 am-fl wid-33"><i></i></li>
							</ol>
						</div>
					</div>
					<div class="mean">
						<p class="sub-title c-default am-text-sm">
							 同族群平均值的BFR：
							 <span class="BFR-av">--</span>
							 <b class="am-text-xs am-fr">
								 同族群（PLM，People Like Me）:与您相似的人群--与您同性别同年龄段的人群
							 </b>
					    </p>
						<div class="line relative am-text-xs am-text-right">
							<ul class="am-cf absolute">
								<li class="step1 bg-low wid-33 am-fl">
									<span>17.0</span>
								</li>
								<li class="step2 bg-default wid-33 am-fl">
									<span>23.0</span>
								</li>
								<li class="bg-danger wid-33 am-fl">
								</li>
							</ul>
							<ol class="line-cont am-cf absolute">
								<li class="step1 am-fl wid-33"><i></i></li>
								<li class="step2 am-fl wid-33"><i></i></li>
							</ol>
						</div>
					</div>
					<div class="introduce am-text-xs">
						<p>体脂率是指人体内脂肪重量在人体总体重中所占的比例，又称体脂百分数，它反映人体内脂肪含量的多少。</p>
						<p>肥胖会提高罹患各种疾病的风险，例如高血压、糖尿病、高血脂等。</p>
						<p>临床标准范围：男性30岁以下14~20，30岁及以上17~23；女性30岁以下17~24，30岁及以上20~27。</p>
					</div>
				</div>
				<div class="bodily-result">
					与同族群相比，您的体脂率<span class="bodily-result-bfr ac-default">--</span>，BMI<span class="bodily-result-bmi ac-default">--</span>，因此您的体型在同族群中属于<span class="bodily-result-belong ac-default">--</span>
				</div>
				<ul class="result-bodily-legend am-cf">
					<li class="result-bodily-left am-fl wid-33">
						<div class="bg-fff am-text-center">
							<!-- <p class="am-text-xs">体脂率<span>偏低</span>，根据BMI划分</p> -->
							<ol class="am-cf">
								<li data-bodily='1' class="am-fl wid-33">
									<img src="/assets/img/xiaoshou.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">消瘦</p>
									<p class="margin-top-8">体脂率偏低</p>
									<p>BMI偏低</p>
								</li>
								<li data-bodily='4' class="am-fl wid-33">
									<img src="/assets/img/jingshi.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">精实</p>
									<p class="margin-top-8">体脂率偏低</p>
									<p>BMI适中</p>
								</li>
								<li data-bodily='7' class="am-fl wid-33">
									<img src="/assets/img/jianmei.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">健美</p>
									<p class="margin-top-8">体脂率偏低</p>
									<p>BMI偏高</p>
								</li>
							</ol>
						</div>
					</li>
					<li class="result-bodily-center am-fl wid-33">
						<div class="bg-fff am-text-center">
							<!-- <p class="am-text-xs">体脂率<span>适中</span>，根据BMI划分</p> -->
							<ol class="am-cf">
								<li data-bodily='2' class="am-fl wid-33">
									<img src="/assets/img/miaotiao.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">苗条</p>
									<p class="margin-top-8">体脂率适中</p>
									<p>BMI偏低</p>
								</li>
								<li data-bodily='5' class="am-fl wid-33">
									<img src="/assets/img/shizhong.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">适中</p>
									<p class="margin-top-8">体脂率适中</p>
									<p>BMI适中</p>
								</li>
								<li data-bodily='8' class="am-fl wid-33">
									<img src="/assets/img/zhuangshuo.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">壮硕</p>
									<p class="margin-top-8">体脂率适中</p>
									<p class="">BMI偏高</p>
								</li>
							</ol>
						</div>
					</li>
					<li class="result-bodily-right am-fl wid-33">
						<div class="bg-fff am-text-center">
							<!-- <p class="am-text-xs">体脂率<span>偏高</span>，根据BMI划分</p> -->
							<ol class="am-cf">
								<li data-bodily='3' class="am-fl wid-33">
									<img src="/assets/img/yinxingfeipang.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">隐性肥胖</p>
									<p class="margin-top-8">体脂率偏高</p>
									<p>BMI偏低</p>
								</li>
								<li data-bodily='6' class="am-fl wid-33">
									<img src="/assets/img/yinxingfeipang.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">隐性肥胖</p>
									<p class="margin-top-8">体脂率偏高</p>
									<p>BMI适中</p>
								</li>
								<li data-bodily='9' class="am-fl wid-33">
									<img src="/assets/img/feipang.png" alt="">
									<p class="am-text-xs bodily-tip margin-top-8">肥胖</p>
									<p class="margin-top-8">体脂率偏高</p>
									<p>BMI偏高</p>
								</li>
							</ol>
						</div>
					</li>
				</ul>
				<div class="bodily_tips"></div>
			</div>
			
			<!-- 生化指标 -->
			<div class="result-biochemical">
				<h3 class="am-text-lg">生化指标</h3>
				<div class="module-explain">
					<p>生化检验”是检测血液中的各种离子、糖类、脂类、蛋白质以及各种酶、激素和机体的多种代谢产物的含量，作为个人健康的“参照物”来推测身体健康程度。</p>
					<p>临床上生化检验的目的在于疾病的诊断、追踪治疗效果、病情预后指标及疾病的筛检。健检时生化检验的目的在于找出未被发现的疾病，以达早期发现；以便早期治疗，提早疗效。</p>
					<p>生化检查除临床与健检在处理“既病”的相关应用外，我们更是可以利用相关生化检查数据与生活习性问卷，发现可能的“未病”与“欲病”，以阻断疾病的发生。</p>
					<p>大部分生化检验结果正常与否，均会参考其是否落在正常值范围而判定。若检验数据异常，表示某种疾病存在的机率较大；反之检验数据正常，表示某种疾病存在的机率较低。</p>
					<p>有时候，单一的检验值虽在正常范围，但受其他健康条件的影响，或许有很大的机率无法有效的反映该检验的功能，而表现失效，也就是说任一检验反应在疾病上，都有“假阳性”与“假阴性”的问题。为避免单一检验的失效，我们利用临床数据库，综合各项检验与其他条件，建立疾病预测模型，再以您的生化检验值的结果，估算个人未来各类慢性病、癌症、重大病症、失能或死亡发生的机率，这是一般健检报告无法表现与提供的。</p>
					<p>生化检验结果反应了个人健康程度，但是健康程度又与年龄、性别有关，所以多数生化检验值正常范围应随年龄、性别不同而变化，因此我们也提供同族群（People Like Me--与您相似的人群--与您同性别同年龄段的人群）的各项检验值的平均数，以找出您需维持或改善的项目与目标，作为健康管理的参考依据。现代社会生活节奏快压力大，不健康的生活方式愈发普遍，因而同族群平均值也会受到相应的影响，例如对于中老年人群，血糖、胆固醇等四高相关指标的同族群平均值有可能显著偏高，甚至超出临床标准范围，这种情况反而更加说明定期检查与健康管理的重要性。我们希望您能结合“临床标准”与“同族群平均值”两个维度，更加全面、客观的掌握自身的健康状况。</p>
				</div>
				<div class="bg-fff shadow-default">
					<p class="am-text-xs">同族群（PLM，People Like Me）:与您相似的人群--与您同性别同年龄段的人群</p>
					<table class="am-text-sm color-extr wid-100"  border="0" cellspacing="0" cellpadding="0">
						<thead>
							<tr>
								<th></th>
								<th>主要检查项目</th>
								<th>检查结果</th>
								<th>临床标准范围</th>
								<th class="color-extr">与临床标准比较</th>
								<th class="group-v">同类族群平均值</th>
								<th class="color-extr">与同类族群比较</th>
							</tr>
						</thead>
						<tbody>
							
						</tbody>
					</table>
				</div>
				
				<!-- 潜在疾病 -->
				<div class="latent-dis">
					<div class="illness-rates am-hide">
						<div class="module-explain">
							<h4>潜在疾病风险评估</h4>
							<p>为预测健康人群“未病”与亚健康人群“欲病”的发生、及医疗需求人群“既病”病情的演变，团队利用海量健康与临床资料，透过大数据分析建立疾病预测模式，将个人生活习性问卷、体态评估、生化检验结果评估等健康影响因子等资料，评估与预测个人可能罹患各项疾病的风险，并透过同族群People Like Me模式，得到个人罹病的风险倍率，若倍率高于1愈多，表示个人罹患该疾病可能风险愈高。</p>
							<p>罹病的风险倍率代表着健康管理与疾病管理的迫切性，透过有效且积极的管理，将能有效的降低风险倍率，以阻断“未病/欲病”的发生，或防止“既病”的进一步恶化。</p>
							<p>因此，建议当倍率大于1时，宜针对高风险疾病每半年追踪检查评估一次；当倍率大于2时，宜针对高风险疾病做进一步的检查或每半年追踪检查评估一次。</p>
						</div>
						<div class="result-biochemical-chart shadow-default wid-100 bg-fff">
							
						</div>
					
						<!-- 风险倍率建议 -->
						<div class="rates-suggest">
							<h5 class="am-text-sm color-extr">建议进一步检查或每半年追踪检查评估的项目：（风险倍率≥2的疾病结果）</h5>
							<table class="rates-suggest-danger wid-100" border="0" cellspacing="0" cellpadding="0">
							
							</table>
						
							<h5 class="am-text-sm color-extr">建议每半年追踪检查评估的项目：（1≤风险倍率＜2的疾病结果）</h5>
							<table class="rates-suggest-waring wid-100" border="0" cellspacing="0" cellpadding="0">
							
							</table>
										
						</div>
					</div>
					
					<!-- 未来健康风险预测 -->
					<div class="result-health am-hide">
						<!-- 亚健康：四高风险  医疗需求者：癌症风险 -->
						<div class="result-three-highs am-hide result-health">
							<!-- 亚健康：四高风险 -->
							<div class="three-highs am-hide">
								<h3 class="am-text-lg wid-100">四高风险倍率</h3>
								<div class="module-explain">
									<p class="three-highs_p">心血管疾病是人类头号杀手，每年因心血管疾病死亡的人数占总死亡人数的30%以上。</p>
									<p class="three-highs_p">心血管疾病包括心脏病、中风和血管疾病等。中国的中风发病率是西方国家的4倍。</p>
									<p class="three-highs_p">由心血管疾病引发的死亡约80%发生在中低收入家庭，且多发生在人的壮年期。</p>
									<div class="three_highs_text">导致心血管疾病的主要因素就是“四高”疾病：高血压、糖尿病、高胆固醇(高血脂)和高尿酸(痛风)。</div>
									<div class="three_highs_text">“四高”疾病可能引发各种严重的并发症，除了心血管疾病以外，常见的有肾脏损害、眼底损害等，这些并发症都可能危及生命。</div>
									<div class="three_highs_text">为了身体健康和生命安全，应经常检测血压、血糖，尽量做到“早发现、早介入、早阻断”，在“四高”疾病实际发生以前降低相关风险，及时阻断或延缓相关疾病和并发症的发生。</div>
								</div>
								<ul class="three-highs-list am-cf">
									
								</ul>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			<h2>未来健康预测</h2>
			
			<!-- 未来疾病风险预测 -->
			<div class="result-illness">
				<h3 class="am-text-lg">未来疾病风险预测—<span class="illness-name"></span></h3>
				<div class="module-explain">
					
				</div>
				<div class="five am-hide am-cf shadow-default">
					<div class="left am-fl bg-fff">
						<div class="result-illness-chart">
							
						</div>
						<div class="symptom am-text-xs">
							<b class="color-extr">主要症状：</b>
							<span>--</span>
						</div>
					</div>
					<ul class="right am-scrollable-vertical am-fl bg-fff">
						
					</ul>
				</div>
				<ul class="default am-hide am-cf p_20">
					
				</ul>
			</div>
			
			<!-- 未来健康风险预测 -->
			<div class="result-health am-hide">
				<!-- 亚健康：四高风险  医疗需求者：癌症风险 -->
				<div class="result-three-highs am-hide margin-t-20 result-health">
					<!-- 医疗需求者：癌症风险 -->
					<div class="illness-cancer am-hide am-cf">
						<h6 class="medical-needs-tit am-text-default">癌症风险预测</h6>
						<div class="module-explain" style="margin-top: 0;">
							<p>根据临床数据研究，癌症常是一种或多种“生活习惯病”日积月累形成“癌症病根”，在个人免疫力或健康不佳时，病根就会适时浮现为“癌症确诊”。因此癌症的防治，除须有良好的健康管理，增强体力与免疫力；慢性病患更需体会各既有慢性病对癌症可能触发的影响，了解各项慢性病的发展，并确实做好慢性病的管理，以有效降低癌症发生的风险。</p>
							<p>罹患癌症不仅影响生活品质，甚至威胁生命。但癌症不可怕，依据科学的方法早期发现“癌症病根”，早期阻断；或早期发现，早期治疗，常可获得控制，延长寿命。</p>
						</div>
						<ul class="left am-scrollable-vertical am-fl bg-fff">
						
						</ul>
						<div class="right am-fl bg-fff">
							<div class="result-cancer-chart">
								
							</div>
							<div class="symptom am-text-xs">
								<b class="color-extr">主要症状：</b>
								<span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- 医疗费用预测 -->
			<div class="result-medical">
				<h3 class="am-text-lg">医疗费用预测</h3>
				<div class="module-explain">
					<p>健康问题除会造成生命损失外，也会形成个人与家庭的经济压力。利用疾病现况评估与预测，可知个人在未来可能发生的慢性疾病、主要急重症或癌症，团队可预估测未来数年医疗使用（如门诊、住院、手术、用药等）的频率与相关费用趋势；从趋势图中，可体会健康促进、健康管理与疾病管理的重要性，持续不断健康促进，除可以改善生活(命)的品质，也能减轻或消除不必要的医疗费用负担。</p>
					<p>从医疗费用的变化趋势与相关的健康评估预测，或可供做个人保险、投资理财等生涯规划之参考。</p>
					<p>设算方式是以大陆主要城市（上海、深圳等地）及香港、台湾公开的统计数据，包含 GDP(国民生产毛额)、NHE(国民医疗保健支出)、SHA(医疗卫生总开支帐目系统) ，比较历年数据的差异，定义各城市的相对权重，再依相关费用资料为基准，进行设算。</p>
					<p>社会、经济环境快速改变，个人消费水平差距悬殊，此医疗费用预测结果仅取代表最大部分人的医疗健康“基础”支出数据，仅供参考。</p>
				</div>
				<ul class="medical-cost am-cf">
					<li class="lifetime am-fl shadow-default wid-50">
						<dl class="wh-100 bg-fff">
							<dd class="am-text-xs">
								<span class="am-text-xl fontw-bold">--</span>
								元
							</dd>
							<dt class="color-extr am-text-xs fontw-normal">终生医疗费用预估</dt>
						</dl>
					</li>
					<li class="ten-years shadow-default am-fl wid-50">
						<dl class="wh-100 bg-fff">
							<dd class="am-text-xs">
								<span class="am-text-xl fontw-bold">--</span>
								元
							</dd>
							<dt class="color-extr am-text-xs fontw-normal">未来<b class="year-length fontw-normal"></b>年医疗费用预估</dt>
						</dl>
					</li>
				</ul>
				<div class="result-medical-chart shadow-default">
					
				</div>
			</div>
			
			<h2 class="health-self-tit">个人健康管理</h2>
			
			<!-- 不患病&amp;增寿 -->
			<div class="result-noSick am-hide">
				<h3 class="am-text-lg">不患病&amp;增寿</h3>
				<div class="module-explain">
					<p>许多慢性病是生活习惯不良所引起，故又称为生活习惯病或文明病。疾病会影响寿命长短，若能降低罹患风险，寿命就会相应延长。 决定长寿的因素很多，长寿不是一早注定的，而是通过自己的长期坚持与努力获得的。只要控制好健康风险，任何人都可以比较健康长寿。</p>
					<p>在此部分给出的，是基于相关疾病，针对您的性别、年龄、基本健康风险等基本情况，计算各年龄的患病概率，再找出其中患病概率最高的岁数。 例如：<span class="illness_li_1">癌症（恶性肿瘤）</span>，最高风险概率<span class="illness_li_2">9</span>%，疾病影响最高年岁<span class="illness_li_3">85</span>岁，不患病可增寿年数<span class="illness_li_4">5</span>年——指的是对于该用户来说，其癌症的患病概率会在<span class="illness_li_3">85</span>岁之前持续上升，在<span class="illness_li_3">85</span>岁时达到最高的<span class="illness_li_2">9</span>%，在<span class="illness_li_3">85</span>岁之后则转为下降；若终生不得<span class="illness_li_1">癌症（恶性肿瘤）</span>，则预期可增寿<span class="illness_li_5">5</span>年。</p>
				</div>
				<div class="bg-fff shadow-default">
					<table class="am-text-sm color-extr wid-100" border="0" cellspacing="0" cellpadding="0">
						<thead>
							<tr class="c-default">
								<th colspan="2"></th>
								<th class="fontw-normal">最高风险概率</th>
								<th class="fontw-normal">疾病影响最高年岁</th>
								<th class="fontw-normal">不患病可增寿年数</th>
							</tr>
						</thead>
						<tbody>
						
						</tbody>
					</table>
				</div>
			</div>
			
			<!-- 健康管理和改善方案 -->
			<div class="result-improve am-hide margin-t-20">
				<div class="improve-biochemical margin-t-20 bg-fff">
					<h5 class="am-text-default">生化指标改善</h5>
					<div class="module-explain">
						<p>由于生化指标常是身体或疾病的内在表征，故对于改善生化指标应以整体而言之，即通过饮食运动改善或治疗疾病获得改善。整体健康风险得到改善，个人许多关键指标也会随之得到改善，当然这种改善是一定范围内的，毕竟大部分疾病是不“可逆”的，许多风险从量变到达质变后，便很难逆转并恢复原状，但可以通过合理手段持续控制。因此，尽可能早的、经常性的身体检查结合大数据评估，便是最佳的发现与应对手段。</p>
						<p>“早发现、早介入、早阻断”，用科学的办法将风险控制在一定水平，并结合客制化可穿戴或居家设备实时监测健康风险，可在目前科技手段下将疾病风险或重症发作的风险降至最低。</p>
						<p>关于单一项生化指标的改善内容，建议可参考健检或专业医疗机构的建议资讯，并于3-6个月周期内持续追踪；另外，也可请专业医师确认原因，遵循其诊断和意见。</p>
						<p>生化指标会经常发生改变，代表着健康状况和健康风险发生了变化。定期使用大数据进行分析，及时发现问题，预防疾病，掌握健康。</p>
					</div>
					<p class="am-text-xs am-text-center color-extr">
						<!-- 生化指标会经常发生改变，代表著健康状况和健康风险发生了变化。定期使用大数据进行分析，及时发现问题，预防疾病，掌握健康。<br> -->
						根据您的健康状况，我们建议您在短期内对生化指标进行适当改善，改善目标如下：
					</p>
					<table class="improve-biochemical-table am-text-sm color-extr wid-100"  border="0" cellspacing="0" cellpadding="0">
						<thead>
							<tr>
								<td></td>
								<td>主要检查项目</td>
								<td>检查结果</td>
								<td>健康风险</td>
								<td>短期改善目标</td>
							</tr>
						</thead>
						<tbody>
							
						</tbody>
					</table>
					<div class="improve-icons">
						<i class="iconfont iconxiaolian">
							<span class="am-text-xs color-extr">：良好</span>
						</i>
						<i class="iconfont iconbukubuxiaolian">
							<span class="am-text-xs color-extr">：一般</span>
						</i>
						<i class="iconfont iconkulian c-danger">
							<span class="am-text-xs color-extr fontw-normal">：需要改善</span>
						</i>
					</div>
				</div>
				
				<h3 class="health-improve am-text-lg">健康管理和改善方案</h3>
				<div class="module-explain">
					<p>不改变不良的生活习惯，健康将难以有效改善，导致疾病，甚至疾病会恶化。</p>
					<p>WHO指出慢性病（含癌症）四大危险因子为烟、酒、不健康饮食及缺乏规律运动；公共卫生也强调“预防”重于治疗；国内外研究证实，“预防从基因、行为和饮食”三面做起。预防重点在于防止新型致病因子------肥胖或过重，而肥胖或过重又与糟糕的生活习惯、不健康的饮食和运动不足有关。所以要健康促进，应有具体的数据监测与改善致胖环境，对多数人而言，简捷有效且易于追踪管理。</p>
					<p>
						那么如何做好健康管理？
						<br>
						1.养成运动的习惯：美国医界报告，提出运动可以活化体内的血液循环，提高“自然杀手细胞”的活性，增加体力与免疫力，甚至可以抑制肿瘤细胞，对抗病症。选择有兴趣且能力可及的运动，纵然体力不济者，如果能经常快走也是能达到运动的效果。
						<br>
						2.不可饮食过度：日常吃饭应维持八分饱；如有过重问题时，每一餐都摄取比“自己所设想”的量，再少10～30％的食物，且需注意饮食的均衡与热量摄取的控制。
						<br>
						3.生活规律：“日出而作，日落而息。”顺着“大自然的节奏”来活动，是最符合养生之道的，并保持之。
						
					</p>
					<p>我们提供客制化个人所需的健康促进方式，透过表格可知您该如何设立量化的标准，以改善您的体型、生化值数据、饮食和运动习惯。经过调整饮食、改善生活作息、培养运动习惯等方式，参照米因大数据为您量化的目标，进行有效的建康管理，达到健康促进的目的。</p>
				</div>
				<div class="improve-bodily bg-fff">
					<h5 class="am-text-default">体型的改善</h5>
					<p class="improve-bodily-tit color-extr am-text-sm fontw-bold">您的专属计划方案</p>
					<div class="am-cf am-text-xs">
						<dl class="am-fl wid-25">
							<dt class="color-extr">
								<span class="plan-time fonts-18">--</span>周
							</dt>
							<dd>计划时长</dd>
						</dl>
						<dl class="am-fl wid-25">
							<dt class="color-extr">
								<span class="bmr fonts-18">--</span>大卡
							</dt>
							<dd>平均每日饮食热量</dd>
						</dl>
						<dl class="am-fl wid-25">
							<dt class="color-extr suggest">
								--
								<!-- <span class="fonts-18">-200</span>大卡/日 -->
							</dt>
							<dd>建议热量改善目标</dd>
						</dl>
						<dl class="am-fl wid-25">
							<dt class="color-extr">
								<span class="sport-time fonts-18">--</span>次/周
							</dt>
							<dd>建议运动次数</dd>
						</dl>
					</div>
					<div class="weight_chart_title">体重改善目标</div>
					<div class="weight_chart"></div>
					<div class="weight_chart_title">腰臀比改善目标</div>
					<div class="whr_chart"></div>
					
					<div class="new_bmi">
						<!--BMI 体型 -->
						<div class="new_bmi_cell">您的BMI是 <span class="new_bmi_val"></span>，属于 <span class="new_bmi_b"></span>型；</div>
						<!-- 理想体重 -->
						<div class="new_bmi_cell">您的理想体重为 <span class="new_bmi_lx bmi_lx1" ></span> ~ <span class="new_bmi_lx bmi_lx2"></span>公斤；</div>
						<!--运动和饮食改善 -->
						<div class="new_bmi_p">体重为影响健康的最重要指标之一，过胖或过瘦有可能对健康造成危害，导致疾病的发生，应注意体重的维持及改善，通过运动及饮食来维持及改善体重最为理想，依据您的BMI与理想体重，建议您应<span class="new_bmi_p_v"></span>，及<span class="new_bmi_p_v2"></span>;</div>
						
					</div>
					
					<table class="eat-table am-text-sm wid-100 margin-t-20" border="0" cellspacing="0" cellpadding="0">
						<tr class="tr_bg">
							<td class="color-extr fontw-bold eat-table-title">饮食部分建议</td>
							<!-- <td>饮食现况</td> -->
							<td class="eat-table-desc">建议改善状况</td>
							<!-- <td class="eat-table-sug">建议改善状况</td> -->
						</tr>
						<tr>
							<td colspan="2" class="improve_ka_cell">
								<div class="improve_ka_vege">
									饮食部分建议先<b class="improve_c0" ></b>肉类<span class="meat_ka"></span>，
									再<b class="improve_c0"></b>主食<span class="staple_ka"></span>,
									豆类<span class="beans_ka"></span>，
									水果<span class="fruits_ka"></span>，
									乳制品<span class="cream_ka"></span>。
								</div>
								<div class="improve_ka_vege_2 am-hide">
									饮食部分建议先<b class="improve_c0"></b>主食<span class="staple_ka"></span>,
									豆类<span class="beans_ka"></span>，
									水果<span class="fruits_ka"></span>，
									乳制品<span class="cream_ka"></span>。
								</div>
							</td>
							<!-- <td class="staple">--</td> -->
						</tr>
						
						<tr>
							<td rowspan="5">
								<img src="/assets/img/zhushilei.png" >
								主食类（择一）：
							</td>
							<!-- <td class="staple">--</td> -->
						</tr>
						<tr>
							<td>
								<p class="improve_show_mf">以米饭为主食者，每天请<span class="improve_c0"></span><span class="staple-improve_mf">--</span></p>
							</td>
						</tr>
						<tr>
							<td>
								<p class="improve_show_mt">以面条为主食者，每天请<span class="improve_c0"></span><span class="staple-improve_mt">--</span></p>
							</td>
						</tr>
						<tr>
							<td>
								<p class="improve_show_zl">以杂粮为主食者，每天请<span class="improve_c0"></span><span class="staple-improve_zl">--</span></p>
							</td>
						</tr>
						<tr>
							<td>
								<p class="improve_show_mb">以面包为主食者，每天请<span class="improve_c0"></span><span class="staple-improve_mb">--</span></p>
							</td>
						</tr>
						<tr class="meat_tr am-hide">
							<td rowspan="4">
								<img src="/assets/img/roulei.png" >
								肉类（择一）：
							</td>
							<!-- <td class="meat">--</td> -->
							
						</tr>
						<tr class="meat_tr am-hide">
							<td>
								<p class="improve_show_jc">以牛肉、猪肉、羊肉家畜等，每天请<span class="improve_c0"></span><span class="meat-improve_jc">--</span></p>
							</td>
						</tr>
						<tr class="meat_tr am-hide">
							<td>
								<p class="improve_show_hx">以鱼、虾、蟹，海鲜等，每天请<span class="improve_c0"></span><span class="meat-improve_hx">--</span></p>
							</td>
						</tr>
						<tr class="meat_tr am-hide">
							<td>
								<p class="improve_show_hx">以鸡肉、鸭肉，家禽等，每天请<span class="improve_c0"></span><span class="meat-improve_hx">--</span></p>
							</td>
						</tr>
						
						<tr>
							<td>
								<img src="/assets/img/shucailei.png" >
								蔬菜类：
							</td>
							<td>
								<div class="vegetables-tr">
									<span>指的是叶菜（如菠菜、高丽菜）、花菜（如花椰菜、金针花）、海菜（如紫菜、海带）、菇类（如香菇、洋菇）等，每日宜多变化并以当季新鲜为主。每天请 <span style="color: rgb(254, 152, 0)">增加</span><span class="vegetables-improve">--</span></span>
								</div>
							</td>
						</tr>
						
						<tr>
							<td>
								<img src="/assets/img/doulei.png" >
								豆类：
							</td>
							<td>
								<div class="beans-tr">
									<span>指的是提供丰富植物性蛋白质的黄豆、毛豆、黑豆、其他豆类及其制品，如豆腐、无糖豆浆、小方豆干等。每天请<span class="improve_c0"></span><span class="beans-improve">--</span></span>
								</div>
							</td>
							<!-- <td class="beans">--</td> -->
							
						</tr>
						<tr>
							<td>
								<img src="/assets/img/shuiguolei.png" >
								水果类：
							</td>
							<td>
								<div class="fruits-tr">
									<span>不含蔬菜。每天请<span class="improve_c0"></span><span class="fruits-improve">--</span></span>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<img src="/assets/img/rupinlei.png" >
								乳脂类：
							</td>
							<td>
								<div class="cream-tr">
									<span>指的是鲜乳、保久乳、优酪乳及乳酪等乳制品。每天请<span class="improve_c0"></span><span class="cream-improve">--</span></span>
								</div>
							</td>
						</tr>
					</table>
					<p class="explain am-text-xs">说明：1、一份约一个成人拳头或手掌大&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、一杯约500cc</p>
					<p class="improve-bodily-tit am-text-sm color-extr fontw-bold">运动部分建议</p>
					<p class="color-extr am-text-xs">运动项目依强度，分为低强度、中强度与高强度，各强度运动项目可参考下列所述，建议根据个人作息时间与身心负担，进行适当的弹性调整。</p>
					<table class="sport-suggest margin-t-20 c-333 wid-100 am-text-sm" border="0" cellspacing="0" cellpadding="0">
						<tr>
							<th></th>
							<th class="fontw-normal">低强度运动</th>
							<th class="fontw-normal">中强度运动</th>
							<th class="fontw-normal">高强度运动</th>
						</tr>
						<tr>
							<td>增加次数</td>
							<td>每周<span class="s1">--</span>次</td>
							<td>每周<span class="s2">--</span>次</td>
							<td>每周<span class="s3">--</span>次</td>
						</tr>
						<tr>
							<td>时长</td>
							<td>30分钟</td>
							<td>30分钟</td>
							<td>30分钟</td>
						</tr>
					</table>
					<ul class="sport-ways am-text-xs">
						<li>低强度运动：如散步、做家事、逛街、遛狗等； </li>
						<li>中强度运动：如快步走、有氧运动、打太极拳、骑脚踏车、打高尔夫球、爬楼梯、跳舞、打桌球、骑马等； </li>
						<li>高强度运动：如游泳、打网球、打篮球、跑步、仰卧起坐、滑雪、跳绳等。</li>
					</ul>
				</div>
			
			
			</div>
			
			
			<!-- 骨折信息 -->
			<div class="fb-status" id="fb-result">
				<h2>骨密度风险评估</h2>
				<div class="analyse-wrap">
					<div class="analyse">
						<div class="analyse-title">骨质状况分析</div>
						<div class="analyse-list flex">
							<div class="item gz-zz">
								<div class="img-box">
									<img src="/assets/img/gz-zz.png" class="img" alt="骨质正常">
								</div>
								<div class="text">骨质正常</div>
							</div>
							<div class="item gz-bz">
								<div class="img-box ">
									<img src="/assets/img/gz-bz.png" class="img" alt="骨质不足">
								</div>
								<div class="text">骨质不足</div>
							</div>
							<div class="item gz-ss">
								<div class="img-box">
									<img src="/assets/img/gz-ss.png" class="img" alt="骨质疏松">
								</div>
								<div class="text">骨质疏松</div>
							</div>
						</div>
					</div>
					<div class="probability fb-risk padding-top-32">
						<div class="analyse-title">未来骨折风险概率</div>
						<div class="analyse-title-desc margin-top-12">
							<p>骨折多由外伤暴力而造成，例如撞击、摔倒、高处跌落的冲击等。严重的多发性骨折可导致休克，甚至危及生命。</p>
						</div>
						<div class="analyse-title-desc">
							<p>骨折是一个结果，而造成骨折的原因是多种多样的。对于中老年个人而言，因各种疾病和身体机能下降造成的贫血、晕眩、身体失衡等症状会大大增加摔倒和冲击的可能，从而增加骨折的风险。</p>
						</div>
						<div class="flex">
							<div class="probability-five ">
								<div class="title">未来五年</div>
								<div class="item flex ">
									<div class="item-title">骨折的风险概率</div>
									<div class="slider1"></div>
								</div>
								<div class="item flex">
									<div class="item-title">骨折复发的风险概率</div>
									<div class="slider2"></div>
								</div>
								<div class="item flex">
									<div class="item-title">骨折复发多次的风险概率</div>
									<div class="slider3"></div>
								</div>
							</div>
							<div class="probability-five">
								<div class="title">未来十年</div>
								<div class="item flex">
									<div class="item-title">骨折的风险概率</div>
									<div class="slider4"></div>
								</div>
								<div class="item flex">
									<div class="item-title">骨折复发的风险概率</div>
									<div class="slider5"></div>
								</div>
								<div class="item flex">
									<div class="item-title">骨折复发多次的风险概率</div>
									<div class="slider6"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="die fb-probability padding-top-32">
						<div class="die-title">因骨折导致严重伤害的风险概率</div>
						<div class="die-chart-box flex">
							<div class="die-chart" >
								
							</div>
							<div class="tip">
								<div class="tip-item">
									<div class="tip-title">
										年龄 < 65岁:
									</div>
									<p class="">仅显示5年内的因骨折导致严重伤害的风险概率</p>
								</div>
								<div class="tip-item">
									<div class="tip-title">
										年龄65～74岁:
									</div>
									<p class="">显示3，5年内的因骨折导致严重伤害的风险概率；</p>
								</div>
								<div class="tip-item">
									<div class="tip-title">
										年龄75岁以上:
									</div>
									<p class="">显示2，3，5年内的因骨折导致严重伤害的风险概率</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<h2>改善建议</h2>
				<div class="density ">
					<div class="density-wrap">
						<div class="fb-tscore">
							<div class="density-title">骨密度值为：<span id='tscore'></span> ，建议改善目标如下图：</div>
							<div class="density-proposal">
								
							</div>
							<div class="density-echart-box">
								<div class="density-echart"></div>
								<div class="density-echart-mask"> 
									<div class="mask-cursor cursor-pointer">
										<div class="mask-cursor-line am-hide"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="density-list">
							
							<div class="item yingy">
								<div class="item-title"><span>若您保持营养均衡，不贫血：</span></div>
								<div class="item-content"></div>
							</div>
							<div class="item smoke">
								<div class="item-title "></div>
								<div class="item-content"></div>
							</div>
							<div class="item drink">
								<div class="item-title"></div>
								<div class="item-content"></div>
							</div>
						</div>
						<div class="proposal fb-diet">
							<div class="density-title">饮食改善建议</div>
							<div class="proposal-list">
								<div class="proposal-desc">依据您的年龄、性别、生活习惯等情况，提供关于骨折风险的建议如下：</div>
								<div class="proposal-item">
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			
		</div>
		<div class="score_popup am-hide">
			<div class="score_popup_wrap">
				<div class="score_popup_title">健康分的相关性</div>
				<div class="score_popup_close">
					<img class="score_popup_close_icon" src="/assets/img/login_close.png" alt=""> 
				</div>
				<div class="score_relevance_list">
					<div class="rel1 relevance_item relevance_item_active">健康分与三高疾病的相关性</div>
					<div class="rel2 relevance_item">健康分与其余常见慢性疾病的相关性</div>
					<div class="rel3 relevance_item">健康分与医疗利用的相关性</div>
					<div class="rel4 relevance_item">健康分与重大伤病的相关性</div>
				</div>
				<div class="score_echat_box">
					<div class="sel_cell">
						<select name="sangaoVal" id="sangaoVal" class="sangao_val">
							<option value="1">高血压</option>
							<option value="2">高血脂</option>
							<option value="3">高血糖</option>
						</select>
						<select name="dlVal" id="dlVal" class="sangao_val am-hide">
							<option value="1">癌症</option>
							<option value="2">肝炎、肝硬化</option>
							<option value="3">关节炎</option>
							<option value="4">甲状腺异常</option>
							<option value="5">脑中风</option>
							<option value="6">贫血</option>
							<option value="7">气喘</option>
							<option value="8">肾脏病、结石</option>
							<option value="9">失眠</option>
							<option value="10">疼痛</option>
							<option value="11">消化性溃疡</option>
							<option value="12">心脏病</option>
							<option value="13">copd</option>
						</select>
						<select name="medical" id="medical" class="sangao_val am-hide">
							<option value="1">平均门诊次数</option>
							<option value="2">平均医疗费用</option>
							<option value="3">平均住院次数</option>
							<option value="4">平均住院天数</option>
						</select>
						<select name="injuryVal" id="injuryVal" class="sangao_val am-hide">
							<option value="1">重大伤病率</option>
							<option value="2">死亡率</option>
						</select>
					</div>
					<div class="sangao_img_wrap">
						<img class="score_echarts_img" src="/assets/img/dl1.png" alt="">
						<img class="score_echarts_img1 am-hide" src="/assets/img/injury_dl1.png" alt="">
						<div class="dl_bg_line_wrap">
							<div class="dl_bg_line">
								<div class="dl_bg_tip">您的位置</div>
							</div>
						</div>
					</div>
					<div class="echarts_tips">
						<div class="echarts_tips_wm">女</div>
						<div class="echarts_tips_m">男</div>
						<div class="echarts_tips_h am-hide">合计</div>
					</div>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	export default{
		name: "lookReport",
		data() {
			return {
				
			}
		},
		watch: {
			$route() {
				window.location.reload();
			}
		},
		mounted() {
			this.$nextTick(() => {
				var jq = document.createElement('script');
				jq.src = "/assets/js/jquery.min.js";
				
				jq.addEventListener('load', () => {
					this.getScript('amazeui.min', () => {
						this.getScript('echarts.min', () => {
							this.getScript('base', () => {
								this.getScript('respect_health', () => {
									this.getScript('slider', () => {
										
									})
								})
							})
						})
					})
				});
				
				document.body.appendChild(jq);
			})
		},
		methods: {
			//修改信息
			toUpdate() {
				var updateUrl = this.$router.resolve({
					path: '/dataUpdate'
				});
				
				window.open(updateUrl.href);
			},
			
			getScript(name, callback) {
				$.getScript('/assets/js/' + name + '.js',callback);
			}
		}
	}
</script>

<style scoped>
	@import "/assets/css/amazeui.min.css";
	@import "/assets/iconfont/iconfont.css";
	@import "/assets/css/base.css";
	@import "/assets/css/respect_health.css";
	@import "/assets/css/respect_fb.css";
	@import "/assets/css/slider.css";
	
	.p_20 {
		padding: 0.2rem;
	}
	.margin_h {
		margin: 0.14rem 0;
	}
</style>
